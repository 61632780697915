import React from "react";
import MinemationText from "./../MinemationText/Minemationtext";

const ContactInfo = ({ content }) => {
  return (
    <div className="contact-info">
      <div className="number">
        <div>
          <p className="opacity" style={{ opacity: 1 }}>
            <a href="tel:+381 (60) 54-54-246">
              <MinemationText
                text={"+381 (60) 54-54-246"}
                animationName="fadeInUp"
                delay={0}
                duration={1000}
                overflowHidden
                scroll={false}
                windowHeight={90}
                scrollRepeat={false}
              />
            </a>
          </p>
          <p>
            <a href="tel:+7 (916) 593-08-10">
              <MinemationText
                text={"+7 (916) 593-08-10"}
                animationName="fadeInUp"
                delay={0}
                duration={1000}
                overflowHidden
                scroll={false}
                windowHeight={90}
                scrollRepeat={false}
              />
            </a>
          </p>
        </div>
      </div>
      <div className="email">
        <div>
          <p className="opacity">
            <MinemationText
              text={content.emailLabel}
              animationName="fadeInUp"
              delay={0}
              duration={1000}
              overflowHidden
              scroll={false}
              windowHeight={90}
              scrollRepeat={false}
            />
          </p>
          <a href={`mailto:${content.email}`}>
            <p className="value">
              <MinemationText
                text={content.email}
                animationName="fadeInUp"
                delay={0}
                duration={1000}
                overflowHidden
                scroll={false}
                windowHeight={90}
                scrollRepeat={false}
              />
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
